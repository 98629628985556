<template>
  <link rel="shortcut icon" type="image/png" href="/images/favicon.png"/>
  <q-layout view="lHh Lpr lFf">
    <q-header elevated class="glossy bg-purple">
      <q-toolbar>
        <q-btn flat dense round @click="leftDrawerOpen = !leftDrawerOpen" aria-label="Options" icon="menu"/>
        <q-toolbar-title>
          Dungeon Defenders Account Link
        </q-toolbar-title>
      </q-toolbar>
    </q-header>

    <q-drawer v-model="leftDrawerOpen" bordered class="drawer" :width="265">
      <q-list>
        <q-item-label header>Display Options</q-item-label>
        <q-item>
          <q-item-section>
            <q-item-label>Light Mode</q-item-label>
            <div class="q-gutter-sm">
              <q-toggle size="s" color="purple" v-model="optionLightMode" inline @update:model-value="toggleLightMode()"></q-toggle>
            </div>
          </q-item-section>
        </q-item>
      </q-list>
    </q-drawer>

    <q-page-container>
      <q-page class="flex flex-center">
        <div id="q-app" style="min-height: 94vh;">
          <div class="q-pa-md q-gutter-sm">
            <q-banner rounded class="bg-grey">
              <div><q-btn color="red" label="IMPORTANT"></q-btn> <u>Please make sure to read the instructions carefully before proceeding!</u></div>
            </q-banner>
          </div>
          <div class="q-pa-md">
            <q-form @submit="submitForm" @reset="resetForm" class="q-gutter-md">
              <div>Primary Account: Will be the main account for storing game data, entitlements, and future additional account links.</div>
              <div class="q-gutter-md row">
                <q-input class="idInput" filled v-model="primary_playfabid" label="Primary PlayFabId" hint="Located on the Options screen!" lazy-rules :rules="[ val => val && val.length > 0 || 'Please type something']"></q-input>
                <q-input class="idInput" filled v-model="primary_linkcode" label="Primary Link Code" hint="Also on the Options screen!" lazy-rules :rules="[ val => val && val.length > 0 || 'Please type something']"></q-input>
              </div>
              <br>
              <div>Secondary Account: Will be linked to the Primary account and its entitlements will be transfered to the Primary Account.</div>
              <div class="q-gutter-md row">
                <q-input class="idInput" filled v-model="secondary_playfabid" label="Secondary PlayFabId" hint="Located on the Options screen!" lazy-rules :rules="[ val => val && val.length > 0 || 'Please type something']"></q-input>
                <q-input class="idInput" filled v-model="secondary_linkcode" label="Secondary Link Code" hint="Also on the Options screen!" lazy-rules :rules="[ val => val && val.length > 0 || 'Please type something']"></q-input>
              </div>
              <!--<q-select filled readonly v-model="game" label="Game" map-options :options="gameSelection" style="width: 275px"></q-select>-->
              <br>
              <q-btn flat icon="warning" color="red" label="IMPORTANT" class="no-pointer-events"></q-btn><br>
              <q-toggle v-model="firstcheck" color="purple" label="I have read the instructions thoroughly and understand what I'm doing." @click=toggleCheck()></q-toggle><br>
              <q-toggle v-model="secondcheck" color="purple" label="I understand fully that once this action is taken IT CAN NOT BE UNDONE." @click=toggleCheck()></q-toggle><br>
              <q-toggle v-model="thirdcheck" color="purple" label="I TAKE FULL RESPONSIBILTY FOR ANY DATA LOSS DUE TO NOT FOLLOWING THE INSTRUCTIONS." @click=toggleCheck()></q-toggle><br>
              <q-btn flat icon="warning" color="red" label="IMPORTANT" class="no-pointer-events"></q-btn><br>
              <q-toggle v-model="finalcheck" color="red" label="I HAVE CLOSED ALL GAME CLIENTS AND I AM READY TO PROCEED." :disable=finalToggle></q-toggle><br>
              <div>
                <q-btn label="Establish Link" type="submit" color="purple"></q-btn>
                <q-btn label="Reset" type="reset" color="negative" flat class="q-ml-sm"></q-btn>
              </div>
            </q-form>
          </div>
        </div>
      </q-page>
    </q-page-container>
  </q-layout>
</template>

<style>
.body--light .drawer {
  background: #f5f5f5 !important;
}

#terms {
  text-decoration: none;
  color: var(--q-primary);
}

#terms:hover {
  color: var(--q-accent);
}

.q-field--filled.q-field--readonly .q-field__control:before {
  border-bottom-style: none !important;
}

body {
  cursor: url('../public/images/cursor.png'), auto;
}

.idInput {
  width: 250px;
}

.codeInput {
  width: 75px;
}

</style>


<script>
import { ref } from 'vue'
import { pfRequest } from './js/query';
import { Dark, Notify } from 'quasar';

export default {
  name: 'App',
  setup () {
    const searchParams = new URLSearchParams(window.location.search);
    const optionLightMode = ref(localStorage.getItem('optionLightMode')==='true');
    const primary_playfabid = ref(searchParams.get('primary_playfabid'));
    const secondary_playfabid = ref(searchParams.get('secondary_playfabid'));
    const primary_linkcode = ref(searchParams.get('primary_linkcode'));
    const secondary_linkcode = ref(searchParams.get('secondary_linkcode'));
    const firstcheck = ref(false);
    const secondcheck = ref(false);
    const thirdcheck = ref(false);
    const finalcheck = ref(false);
    const finalToggle = ref(true);

    const game = ref(searchParams.get('game') || "dda");

    const toggleLightMode = () => {
      Dark.set(!optionLightMode.value);
      window.localStorage.setItem('optionLightMode', optionLightMode.value);
    };

    const resetForm = () => {
      firstcheck.value = false;
      secondcheck.value = false;
      thirdcheck.value = false;
      finalcheck.value = false;
    };

    const toggleCheck = () => {
      if (firstcheck.value && secondcheck.value && thirdcheck.value) {
        finalToggle.value = false;
      } else {
        finalToggle.value = true;
        finalcheck.value = false;
      }
    }

    const submitForm = async () => {
      if (firstcheck.value == true && secondcheck.value == true && thirdcheck.value == true && finalcheck.value == true && (game.value == "dda" || game.value == "ddgr" )) {
        await pfRequest(game.value.trim(), primary_playfabid.value.trim(), primary_linkcode.value.trim(), secondary_playfabid.value.trim(), secondary_linkcode.value.trim())
          .then((response) => {
            if (response.status != 200) {
              notifyMsg('negative', response.statusText + ' Status Code: ' + response.status, 10000);
              resetForm();
            } else if (response.status == 200) {
              notifyMsg('positive', "Accounts successfully linked! Please wait 5 minutes until playing the game. This message will disappear when 5 minutes have passed.", 300000);
              resetForm();
            }
          });
      }
    };

    const notifyMsg = (type, message, timeout) => {
      Notify.create({
        type,
        progress: true,
        message,
        timeout,
      })
    }

    return {
      gameSelection: [
        { label: 'Dungeon Defenders: Awakened', value: 'dda' }, 
        { label: 'Dungeon Defenders: Going Rogue', value: 'ddgr' },
      ],
      leftDrawerOpen: ref(false),
      optionLightMode,
      primary_playfabid,
      secondary_playfabid,
      primary_linkcode,
      secondary_linkcode,
      firstcheck,
      secondcheck,
      thirdcheck,
      finalcheck,
      finalToggle,
      game,
      toggleLightMode,
      toggleCheck,
      resetForm,
      submitForm,
      notifyMsg
    }
  },
  created() {
    document.title = `Dungeon Defenders Account Link`;
    this.toggleLightMode();
  },
}
</script>
