import axios from "axios";

const pfRequest = (game, primary, pcode, secondary, scode) => {
  const headers = {
    game,
    primary,
    pcode,
    secondary,
    scode,
    "X-Api-Key": "WR1lbUQPtg7pO5cHodD8F3WZzwuDX4ZC8SjE5yXF",
  }

  return axios.post('https://dnn8nwcdlc.execute-api.us-east-1.amazonaws.com/live', {}, { headers, timeout: 30000 })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return ({status: error.response.status, statusText: error.response.data.message});
    });
}

export {
  pfRequest
}